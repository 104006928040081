export enum Agency {
  NOT_SELECTED = "",
  WSP = "WSP",
  WSP_I5 = "WSP: I5",
  WSP_NW = "WSP: NW", 
  WSDOT = "WSDOT",
  KCM = "KCM",
  SFD = "SFD",
  SPD = "SPD",
  NWSA = "NWSA",
  Port = "Port",
  SDOT = "SDOT",
  ST = "ST",
  OTHER = 'Other',
}

export enum AgencyRegionCode {
  NW = "NW",
  I5 = "I5",
}