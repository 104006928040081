import { Alert, Box, Grid, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardIncidents from "../../components/incident-model/dash-incidents";
import AdditionalDetailsModal from "../../components/integrated-dispatch-feed/additional-details-modal";
import {
  IncidentStoreError,
  IncidentStoreSuccess,
} from "../../enums/store-messages/incident";
import { DispatchEventFeedModel, IRootReducer } from "../../reducers";
import { UTCFromTimeFrameUtil, UTCToOneMinuteAheadUtil } from "../../utils/date-utils";
import {
  getDispatchFeedEventRequest,
  getDispatchFeedEventsRequest,
  getFeedStatusRequest,
  getNoteworthyEventsRequest,
  getPinnedDispatchEventsRequest,
  getTrafficCamLayerRequest,
  getOpenIncidentsRequest,
  updateViewportRequest,
} from "../../actions";
import { DispatchEventFeedGrid } from "../../components";
import { MapLayers, MarkerTypes } from "../../enums/map";
import { DispatchFeedStoreError } from "../../enums/store-messages/dispatch";
import { FeedView } from "../../enums/feed-view";
import { awsRum, customTheme } from "../..";
import { RUMPage } from "../../enums/rum";
import { MarkerData } from "../../reducers/states/map";
import VccMap from "../../components/map/vcc-map";


export default function DashboardPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [idfDetails, setIdfDetails] = useState({} as DispatchEventFeedModel);
  const [idfDetailsOpen, setIdfDetailsOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const incidentStore = useSelector(
    (state: IRootReducer) => state.vccIncidentReducer
  );
  const eventStore = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer
  );
  const mapStore = useSelector((state: IRootReducer) => state.mapReducer);

  const getFeedAndFeedStatus = useCallback(
    (fromTimeStamp: Date, toTimeStamp: Date) => {
      dispatch(getPinnedDispatchEventsRequest(fromTimeStamp, toTimeStamp));
      dispatch(getFeedStatusRequest());
      switch (eventStore.feedView) {
        case FeedView.ALL:
          dispatch(
            getDispatchFeedEventsRequest(
              fromTimeStamp,
              toTimeStamp,
              eventStore.showOpenDispatchesOnly,
              eventStore.region,
              eventStore.area,
              false, // set map data
            )
          );
          break;
        case FeedView.NOTEWORTHY:
          dispatch(getNoteworthyEventsRequest());
          break;
      }
    },
    [dispatch, eventStore.feedView, eventStore.showOpenDispatchesOnly, eventStore.region, eventStore.area]
  );

  useEffect(() => {
    try{
      awsRum.recordPageView(RUMPage.DASHBOARD);
    }
    catch{
      // Swallow rum errors
    }
  }, [])
  

  // Update View point to default
  useEffect(() => {
    dispatch(getTrafficCamLayerRequest()); // Review: Move this into the map init
    dispatch(updateViewportRequest());
  }, [dispatch]);

  // Update timeFrame dep entities: incidents, dispatch events, refresh timer
  useEffect(() => {
    // If the timeframe changed, also fetch the latest Active Incident details
    dispatch(getOpenIncidentsRequest());
    
    // Set up timer for grid
    const refreshGrid = () => {
      getFeedAndFeedStatus(
        UTCFromTimeFrameUtil(eventStore.timeFrame),
        UTCToOneMinuteAheadUtil()
      );
    };
    let gridTimer = setInterval(refreshGrid, 45000);
    
    // If the timer is valid, clear the current timer, fetch the latest, then reset the timer
    if (eventStore.timeFrame !== 0) {
      clearInterval(gridTimer);
      getFeedAndFeedStatus(
        UTCFromTimeFrameUtil(eventStore.timeFrame),
        UTCToOneMinuteAheadUtil()
      );
      gridTimer = setInterval(refreshGrid, 45000);
    }
    
    // useEffect cleanup - turn off the timer when this component is closed
    return () => clearInterval(gridTimer);
  }, [
    dispatch,
    getFeedAndFeedStatus,
    eventStore.timeFrame,
    eventStore.feedView,
  ]);

  // Retrieve latest incidents when new notifications come through
  useEffect(() => {
    if (incidentStore.incidentNotifs.length > 0) {
      dispatch(getOpenIncidentsRequest());
    }
  }, [dispatch, incidentStore.incidentNotifs]);

  // Refresh incidents on a successful save
  useEffect(() => {
    if (
      incidentStore.successMessage === IncidentStoreSuccess.INCIDENT_SAVE
    ) {
      dispatch(getOpenIncidentsRequest());
    }
  }, [dispatch, incidentStore.successMessage]);

  // Handle errors from retrieving data
  useEffect(() => {
    if (incidentStore.failureMessage === IncidentStoreError.VCC_INCIDENTS_GET) {
      setErrorMessage(incidentStore.failureMessage);
    }
  }, [incidentStore.failureMessage]);
  useEffect(() => {
    switch (eventStore.failureMessage) {
      case DispatchFeedStoreError.DISPATCH_EVENT_FEED_GET:
      case DispatchFeedStoreError.DISPATCH_EVENTS_PIN_GET:
        setErrorMessage(eventStore.failureMessage);
        break;
    }
  }, [eventStore.failureMessage]);

  useEffect(() => {
    setIdfDetails(eventStore.dispatchEvent);
  }, [eventStore.dispatchEvent]);

  const handleMarkerOnClick = (markerType: string, markerId: string): void => {
    awsRum.recordEvent("dashboard-map-marker-click", {
      markerType: markerType,
      markerId: markerId
    });

    switch(markerType){
      case MarkerTypes.DISPATCH:
        dispatch(getDispatchFeedEventRequest(markerId));
        setIdfDetailsOpen(true);
        break;
      case MarkerTypes.INCIDENT:
        navigate(`/incident-model/${markerId}`);
        break;
    }
  };

  // Format map marker data
  const markerDataSets: MarkerData[] = [
    {
      category: MapLayers.DISPATCH_EVENTS,
      geoData: mapStore.dispatchGeo,
      color: customTheme.marker.DispatchEvent!,
    },
    {
      category: MapLayers.VCC_INCIDENTS,
      geoData: mapStore.incidentGeo,
      color: customTheme.marker.ActiveIncident!,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box m={1}> 
            <VccMap 
              props={{
                markerData: markerDataSets,
                initLayers: [MapLayers.TRAFFIC_LAYER, MapLayers.CAMERA_LAYER,  MapLayers.HIGHWAY_ALERTS_DATA],
                initMarkerSets: [MapLayers.DISPATCH_EVENTS, MapLayers.VCC_INCIDENTS],
                allAvailableLayers: [MapLayers.CAMERA_LAYER, MapLayers.TRAFFIC_LAYER,  MapLayers.HIGHWAY_ALERTS_DATA],
                allAvailableMarkerSets: [MapLayers.DISPATCH_EVENTS, MapLayers.VCC_INCIDENTS],
                markerOnClick: handleMarkerOnClick,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box m={1}>
            <DispatchEventFeedGrid
              props={{
                isDashDisplay: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box m={1}>
            <DashboardIncidents
              props={{
                dispatchAddtlInfo: eventStore.dispatchEvent,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <AdditionalDetailsModal
        props={{
          isOpen: idfDetailsOpen,
          idfEvent: idfDetails,
          handleClose: () => setIdfDetailsOpen(false),
        }}
      />
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
