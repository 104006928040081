export const types = {
  // Dispatch Event Feed
  GET_DISPATCH_FEED_EVENT: "GET_DISPATCH_FEED_EVENT",
  GET_DISPATCH_FEED_EVENT_SUCCESS: "GET_DISPATCH_FEED_EVENT_SUCCESS",
  CLEAR_CURRENT_DISPATCH_EVENT: "CLEAR_CURRENT_DISPATCH_EVENT",
  GET_DISPATCH_FEED_EVENTS: "GET_DISPATCH_FEED_EVENTS",
  GET_DISPATCH_FEED_EVENTS_SUCCESS: "GET_DISPATCH_FEED_EVENTS_SUCCESS",
  GET_DISPATCH_FEED_EVENTS_FAILURE: "GET_DISPATCH_FEED_EVENTS_FAILURE",
  CLEAR_DISPATCH_EVENT_FEED: "CLEAR_DISPATCH_EVENT_FEED",
  GET_TMC_LOG: "GET_TMC_LOG",
  GET_TMC_LOG_SUCCESS: "GET_TMC_LOG_SUCCESS",
  GET_TMC_LOG_FAILURE: "GET_TMC_LOG_FAILURE",
  CLEAR_CURRENT_TMC_LOG: "CLEAR_CURRENT_TMC_LOG",
  GET_NOTEWORTHY_DISPATCH_EVENTS: "GET_NOTEWORTHY_DISPATCH_EVENTS",
  GET_NOTEWORTHY_DISPATCH_EVENTS_SUCCESS: "GET_NOTEWORTHY_DISPATCH_EVENTS_SUCCESS",
  GET_NOTEWORTHY_DISPATCH_EVENTS_FAILURE: "GET_NOTEWORTHY_DISPATCH_EVENTS_FAILURE",
  PIN_DISPATCH_EVENT: "PIN_DISPATCH_EVENT",
  PIN_DISPATCH_EVENT_SUCCESS: "PIN_DISPATCH_EVENT_SUCCESS",
  PIN_DISPATCH_EVENT_FAILURE: "PIN_DISPATCH_EVENT_FAILURE",
  REMOVE_PIN_DISPATCH_EVENT: "REMOVE_PIN_DISPATCH_EVENT",
  REMOVE_PIN_DISPATCH_EVENT_SUCCESS: "REMOVE_PIN_DISPATCH_EVENT_SUCCESS",
  REMOVE_PIN_DISPATCH_EVENT_FAILURE: "REMOVE_PIN_DISPATCH_EVENT_FAILURE",
  GET_PINNED_DISPATCH_EVENTS: "GET_PINNED_DISPATCH_EVENTS",
  GET_PINNED_DISPATCH_EVENTS_SUCCESS: "GET_PINNED_DISPATCH_EVENTS_SUCCESS",
  GET_PINNED_DISPATCH_EVENTS_FAILURE: "GET_PINNED_DISPATCH_EVENTS_FAILURE",
  GET_PINNED_DISPATCH_EVENT: "GET_PINNED_DISPATCH_EVENT",
  GET_PINNED_DISPATCH_EVENT_SUCCESS: "GET_PINNED_DISPATCH_EVENT_SUCCESS",
  GET_PINNED_DISPATCH_EVENT_FAILURE: "GET_PINNED_DISPATCH_EVENT_FAILURE",
  GET_DISPATCH_FEED_STATUS: "GET_DISPATCH_FEED_STATUS",
  GET_DISPATCH_FEED_STATUS_SUCCESS: "GET_DISPATCH_FEED_STATUS_SUCCESS",
  GET_DISPATCH_FEED_STATUS_FAILURE: "GET_DISPATCH_FEED_STATUS_FAILURE",
  INIT_TIME_FRAME: "INIT_TIME_FRAME",
  SET_TIME_FRAME: "SET_TIME_FRAME",
  SET_TIME_FRAME_SUCCESS: "SET_TIME_FRAME_SUCCESS",
  INIT_FEED_VIEW: "INIT_FEED_VIEW",
  SET_FEED_VIEW: "SET_FEED_VIEW",
  SET_FEED_VIEW_SUCCESS: "SET_FEED_VIEW_SUCCESS",
  SET_SHOW_OPEN_DISPATCHES_ONLY: "SET_SHOW_OPEN_DISPATCHES_ONLY",
  SET_SHOW_OPEN_DISPATCHES_ONLY_SUCCESS: "SET_SHOW_OPEN_DISPATCHES_ONLY_SUCCESS",
  INIT_SHOW_OPEN_DISPATCHES_ONLY: "INIT_SHOW_OPEN_DISPATCHES_ONLY",
  SET_FEED_AREA: "SET_FEED_AREA",
  SET_FEED_AREA_SUCCESS: "SET_FEED_AREA_SUCCESS",
  SET_FEED_REGION: "SET_FEED_REGION",
  SET_FEED_REGION_SUCCESS: "SET_FEED_REGION_SUCCESS",
  INIT_FEED_AREA_AND_REGION: "INIT_FEED_AREA_AND_REGION",

  // VCC Incident
  CREATE_VCC_INCIDENT: "CREATE_VCC_INCIDENT",
  CREATE_VCC_INCIDENT_SUCCESS: "CREATE_VCC_INCIDENT_SUCCESS",
  CREATE_VCC_INCIDENT_FAILURE: "CREATE_VCC_INCIDENT_FAILURE",
  UPDATE_VCC_INCIDENT: "UPDATE_VCC_INCIDENT",
  UPDATE_VCC_INCIDENT_SUCCESS: "UPDATE_VCC_INCIDENT_SUCCESS",
  UPDATE_VCC_INCIDENT_FAILURE: "UPDATE_VCC_INCIDENT_FAILURE",
  GET_VCC_INCIDENT: "GET_VCC_INCIDENT",
  GET_VCC_INCIDENT_SUCCESS: "GET_VCC_INCIDENT_SUCCESS",
  GET_VCC_INCIDENT_FAILURE: "GET_VCC_INCIDENT_FAILURE",
  GET_OPEN_INCIDENTS: "GET_OPEN_INCIDENTS",
  GET_OPEN_INCIDENTS_SUCCESS: "GET_OPEN_INCIDENTS_SUCCESS",
  GET_OPEN_INCIDENTS_FAILURE: "GET_OPEN_INCIDENTS_FAILURE",
  GET_CLOSED_DELETED_INCIDENTS: "GET_CLOSED_DELETED_INCIDENTS",
  GET_CLOSED_DELETED_INCIDENTS_SUCCESS: "GET_CLOSED_DELETED_INCIDENTS_SUCCESS",
  GET_CLOSED_DELETED_INCIDENTS_FAILURE: "GET_CLOSED_DELETED_INCIDENTS_FAILURE",
  GET_VCC_INCIDENT_NOTES: "GET_VCC_INCIDENT_NOTES",
  GET_VCC_INCIDENT_NOTES_SUCCESS: "GET_VCC_INCIDENT_NOTES_SUCCESS",
  GET_VCC_INCIDENT_NOTES_FAILURE: "GET_VCC_INCIDENT_NOTES_FAILURE",
  CREATE_VCC_INCIDENT_NOTE: "CREATE_VCC_INCIDENT_NOTE",
  CREATE_VCC_INCIDENT_NOTE_SUCCESS: "CREATE_VCC_INCIDENT_NOTE_SUCCESS",
  CREATE_VCC_INCIDENT_NOTE_FAILURE: "CREATE_VCC_INCIDENT_NOTE_FAILURE",
  GET_VCC_INCIDENT_ACTION: "GET_VCC_INCIDENT_ACTION",
  GET_VCC_INCIDENT_ACTION_SUCCESS: "GET_VCC_INCIDENT_ACTION_SUCCESS",
  GET_VCC_INCIDENT_ACTION_FAILURE: "GET_VCC_INCIDENT_ACTION_FAILURE",
  GET_VCC_INCIDENT_ACTIONS: "GET_VCC_INCIDENT_ACTIONS",
  GET_VCC_INCIDENT_ACTIONS_SUCCESS: "GET_VCC_INCIDENT_ACTIONS_SUCCESS",
  GET_VCC_INCIDENT_ACTIONS_FAILURE: "GET_VCC_INCIDENT_ACTIONS_FAILURE",
  CREATE_VCC_INCIDENT_ACTION: "CREATE_VCC_INCIDENT_ACTION",
  CREATE_VCC_INCIDENT_ACTION_SUCCESS: "CREATE_VCC_INCIDENT_ACTION_SUCCESS",
  CREATE_VCC_INCIDENT_ACTION_FAILURE: "CREATE_VCC_INCIDENT_ACTION_FAILURE",
  MODIFY_VCC_INCIDENT_ACTION: "MODIFY_VCC_INCIDENT_ACTION",
  MODIFY_VCC_INCIDENT_ACTION_SUCCESS: "MODIFY_VCC_INCIDENT_ACTION_SUCCESS",
  MODIFY_VCC_INCIDENT_ACTION_FAILURE: "MODIFY_VCC_INCIDENT_ACTION_FAILURE",
  GET_TALKING_POINT: "GET_TALKING_POINT",
  GET_TALKING_POINT_SUCCESS: "GET_TALKING_POINT_SUCCESS",
  GET_TALKING_POINT_FAILURE: "GET_TALKING_POINT_FAILURE",
  GET_TALKING_POINTS: "GET_TALKING_POINTS",
  GET_TALKING_POINTS_SUCCESS: "GET_TALKING_POINTS_SUCCESS",
  GET_TALKING_POINTS_FAILURE: "GET_TALKING_POINTS_FAILURE",
  CREATE_TALKING_POINT: "CREATE_TALKING_POINT",
  CREATE_TALKING_POINT_SUCCESS: "CREATE_TALKING_POINT_SUCCESS",
  CREATE_TALKING_POINT_FAILURE: "CREATE_TALKING_POINT_FAILURE",
  UPDATE_TALKING_POINT: "UPDATE_TALKING_POINT",
  UPDATE_TALKING_POINT_SUCCESS: "UPDATE_TALKING_POINT_SUCCESS",
  UPDATE_TALKING_POINT_FAILURE: "UPDATE_TALKING_POINT_FAILURE",
  UPLOAD_TALKING_POINT_FILE: "UPLOAD_TALKING_POINT_FILE",
  UPLOAD_TALKING_POINT_FILE_SUCCESS: "UPLOAD_TALKING_POINT_FILE_SUCCESS",
  UPLOAD_TALKING_POINT_FILE_FAILURE: "UPLOAD_TALKING_POINT_FILE_FAILURE",
  DOWNLOAD_TALKING_POINT_FILE: "DOWNLOAD_TALKING_POINT_FILE",
  DOWNLOAD_TALKING_POINT_FILE_SUCCESS: "DOWNLOAD_TALKING_POINT_FILE_SUCCESS",
  DOWNLOAD_TALKING_POINT_FILE_FAILURE: "DOWNLOAD_TALKING_POINT_FILE_FAILURE",
  GET_TALKING_POINT_HISTORY: "GET_TALKING_POINT_HISTORY",
  GET_TALKING_POINT_HISTORY_SUCCESS: "GET_TALKING_POINT_HISTORY_SUCCESS",
  GET_TALKING_POINT_HISTORY_FAILURE: "GET_TALKING_POINT_HISTORY_FAILURE",
  GET_INC_HISTORY: "GET_INC_HISTORY",
  GET_INC_HISTORY_SUCCESS: "GET_INC_HISTORY_SUCCESS",
  GET_INC_HISTORY_FAILURE: "GET_INC_HISTORY_FAILURE",
  GET_OUTREACH: "GET_OUTREACH",
  GET_OUTREACH_SUCCESS: "GET_OUTREACH_SUCCESS",
  GET_OUTREACH_FAILURE: "GET_OUTREACH_FAILURE",
  GET_OUTREACHES: "GET_OUTREACHES",
  GET_OUTREACHES_SUCCESS: "GET_OUTREACHES_SUCCESS",
  GET_OUTREACHES_FAILURE: "GET_OUTREACHES_FAILURE",
  CREATE_OUTREACH: "CREATE_OUTREACH",
  CREATE_OUTREACH_SUCCESS: "CREATE_OUTREACH_SUCCESS",
  CREATE_OUTREACH_FAILURE: "CREATE_OUTREACH_FAILURE",
  UPDATE_OUTREACH: "UPDATE_OUTREACH",
  UPDATE_OUTREACH_SUCCESS: "UPDATE_OUTREACH_SUCCESS",
  UPDATE_OUTREACH_FAILURE: "UPDATE_OUTREACH_FAILURE",
  UPLOAD_OUTREACH_FILE: "UPLOAD_OUTREACH_FILE",
  UPLOAD_OUTREACH_FILE_SUCCESS: "UPLOAD_OUTREACH_FILE_SUCCESS",
  UPLOAD_OUTREACH_FILE_FAILURE: "UPLOAD_OUTREACH_FILE_FAILURE",
  DOWNLOAD_OUTREACH_FILE: "DOWNLOAD_OUTREACH_FILE",
  DOWNLOAD_OUTREACH_FILE_SUCCESS: "DOWNLOAD_OUTREACH_FILE_SUCCESS",
  DOWNLOAD_OUTREACH_FILE_FAILURE: "DOWNLOAD_OUTREACH_FILE_FAILURE",
  GET_OUTREACH_HISTORY: "GET_OUTREACH_HISTORY",
  GET_OUTREACH_HISTORY_SUCCESS: "GET_OUTREACH_HISTORY_SUCCESS",
  GET_OUTREACH_HISTORY_FAILURE: "GET_OUTREACH_HISTORY_FAILURE",
  CLEAR_CURRENT_INC_HISTORY: "CLEAR_CURRENT_INC_HISTORY",
  UPLOAD_INC_IMAGE: "UPLOAD_INC_IMAGE",
  UPLOAD_INC_IMAGE_SUCCESS: "UPLOAD_INC_IMAGE_SUCCESS",
  UPLOAD_INC_IMAGE_FAILURE: "UPLOAD_INC_IMAGE_FAILURE",
  DOWNLOAD_NOTE_FILE: "DOWNLOAD_NOTE_FILE",
  DOWNLOAD_NOTE_FILE_SUCCESS: "DOWNLOAD_NOTE_FILE_SUCCESS",
  DOWNLOAD_NOTE_FILE_FAILURE: "DOWNLOAD_NOTE_FILE_FAILURE",
  CLEAR_PRESIGNED_URL: "CLEAR_PRESIGNED_URL",
  IS_INC_UPDATE_AVAILABLE: "IS_INC_UPDATE_AVAILABLE",
  IS_INC_UPDATE_AVAILABLE_SUCCESS: "IS_INC_UPDATE_AVAILABLE_SUCCESS",
  IS_INC_UPDATE_AVAILABLE_FAILURE: "IS_INC_UPDATE_AVAILABLE_FAILURE",
  DOWNLOAD_VCC_INCIDENT_REPORT: "DOWNLOAD_VCC_INCIDENT_REPORT",
  DOWNLOAD_VCC_INCIDENT_REPORT_FAILURE: "DOWNLOAD_VCC_INCIDENT_REPORT_FAILURE",
  FINALIZE_VCC_INCIDENT_REPORT: "FINALIZE_VCC_INCIDENT_REPORT",
  FINALIZE_VCC_INCIDENT_REPORT_FAILURE: "FINALIZE_VCC_INCIDENT_REPORT_FAILURE",
  FINALIZE_VCC_INCIDENT_REPORT_SUCCESS: "FINALIZE_VCC_INCIDENT_REPORT_SUCCESS",

  // Notifications
  CREATE_INC_NOTIF: "CREATE_INC_NOTIF",
  CREATE_INC_NOTIF_SUCCESS: "CREATE_INC_NOTIF_SUCCESS",
  CREATE_INC_NOTIF_FAILURE: "CREATE_INC_NOTIF_FAILURE",
  INIT_INC_NOTIFS: "INIT_INC_NOTIFS",
  CLEAR_ALL_NOTIFS: "CLEAR_ALL_NOTIFS",
  CLEAR_ALL_NOTIFS_SUCCESS: "CLEAR_ALL_NOTIFS_SUCCESS",
  CLEAR_ALL_NOTIFS_FAILURE: "CLEAR_ALL_NOTIFS_FAILURE",
  CLEAR_NOTIF: "CLEAR_NOTIF",
  CLEAR_NOTIF_SUCCESS: "CLEAR_NOTIF_SUCCESS",
  CLEAR_NOTIF_FAILURE: "CLEAR_NOTIF_FAILURE",
  CLEAR_BANNER_NOTIF: "CLEAR_BANNER_NOTIF",

  // Admin
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  CREATE_AND_INVITE_USER: "CREATE_AND_INVITE_USER",
  CREATE_AND_INVITE_USER_SUCCESS: "CREATE_AND_INVITE_USER_SUCCESS",
  CREATE_AND_INVITE_USER_FAILURE: "CREATE_AND_INVITE_USER_FAILURE",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  GET_GROUPS_BY_USER: "GET_GROUPS_BY_USER",
  GET_GROUPS_BY_USER_SUCCESS: "GET_GROUPS_BY_USER_SUCCESS",
  GET_GROUPS_BY_USER_FAILURE: "GET_GROUPS_BY_USER_FAILURE",
  GET_AGENCY_BY_USER: "GET_AGENCY_BY_USER",
  GET_AGENCY_BY_USER_SUCCESS: "GET_AGENCY_BY_USER_SUCCESS",
  GET_AGENCY_BY_USER_FAILURE: "GET_AGENCY_BY_USER_FAILURE",
  ADD_USER_TO_GROUP: "ADD_USER_TO_GROUP",
  ADD_USER_TO_GROUP_SUCCESS: "ADD_USER_TO_GROUP_SUCCESS",
  ADD_USER_TO_GROUP_FAILURE: "ADD_USER_TO_GROUP_FAILURE",
  REMOVE_USER_FROM_GROUP: "REMOVE_USER_FROM_GROUP",
  REMOVE_USER_FROM_GROUP_SUCCESS: "REMOVE_USER_FROM_GROUP_SUCCESS",
  REMOVE_USER_FROM_GROUP_FAILURE: "REMOVE_USER_FROM_GROUP_FAILURE",
  REINVITE_USER: "REINVITE_USER",
  REINVITE_USER_SUCCESS: "REINVITE_USER_SUCCESS",
  REINVITE_USER_FAILURE: "REINVITE_USER_FAILURE",

  // GeoJson
  GET_TRAFFIC_CAM_GEO_JSON: "GET_TRAFFIC_CAM_GEO_JSON",
  GET_TRAFFIC_CAM_GEO_JSON_SUCCESS: "GET_TRAFFIC_CAM_GEO_JSON_SUCCESS",
  GET_TRAFFIC_CAM_GEO_JSON_FAILURE: "GET_TRAFFIC_CAM_GEO_JSON_FAILURE",

  // User Info
  GET_USER_INFO: "GET_USER_INFO",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",
  GET_ALL_READYOP_GROUPS: "GET_ALL_READYOP_GROUPS",
  GET_ALL_READYOP_GROUPS_SUCCESS: "GET_ALL_READYOP_GROUPS_SUCCESS",
  GET_ALL_READYOP_GROUPS_FAILURE: "GET_ALL_READYOP_GROUPS_FAILURE",
  GET_USER_READYOP_GROUPS: "GET_USER_READYOP_GROUPS",
  GET_USER_READYOP_GROUPS_SUCCESS: "GET_USER_READYOP_GROUPS_SUCCESS",
  GET_USER_READYOP_GROUPS_FAILURE: "GET_USER_READYOP_GROUPS_FAILURE",
  UPDATE_USER_READYOP_GROUPS: "UPDATE_USER_READYOP_GROUPS",
  UPDATE_USER_READYOP_GROUPS_SUCCESS: "UPDATE_USER_READYOP_GROUPS_SUCCESS",
  UPDATE_USER_READYOP_GROUPS_FAILURE: "UPDATE_USER_READYOP_GROUPS_FAILURE",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",

  // Map
  UPDATE_MAP_VIEWPORT: "UPDATE_MAP_VIEWPORT",
  SET_DISPATCH_GEO: "SET_DISPATCH_GEO",
  SET_DISPATCH_GEO_SUCCESS: "SET_DISPATCH_GEO_SUCCESS",
  SET_DISPATCH_GEO_FAILURE: "SET_DISPATCH_GEO_FAILURE",
  SET_DISPATCH_GEO_VIA_GRID: "SET_DISPATCH_GEO_VIA_GRID",
  SET_SELECTED_DISPATCH_IDS: "SET_SELECTED_DISPATCH_IDS",
  SET_SELECTED_DISPATCH_IDS_SUCCESS: "SET_SELECTED_DISPATCH_IDS_SUCCESS",
  SET_SELECTED_DISPATCH_IDS_FAILURE: "SET_SELECTED_DISPATCH_IDS_FAILURE",
  SET_ASSOCIATED_DISPATCH_GEO: "SET_ASSOCIATED_DISPATCH_GEO",
  SET_ASSOCIATED_DISPATCH_GEO_SUCCESS: "SET_ASSOCIATED_DISPATCH_GEO_SUCCESS",
  SET_ASSOCIATED_DISPATCH_GEO_FAILURE: "SET_ASSOCIATED_DISPATCH_GEO_FAILURE", 
  SET_INCIDENT_GEO: "SET_INCIDENT_GEO",
  SET_INCIDENT_GEO_SUCCESS: "SET_INCIDENT_GEO_SUCCESS",
  SET_INCIDENT_GEO_FAILURE: "SET_INCIDENT_GEO_FAILURE",
  SET_CURRENT_INCIDENT_GEO: "SET_CURRENT_INCIDENT_GEO",
  SET_CURRENT_INCIDENT_GEO_SUCCESS: "SET_CURRENT_INCIDENT_GEO_SUCCESS",
  SET_CURRENT_INCIDENT_GEO_FAILURE: "SET_CURRENT_INCIDENT_GEO_FAILURE",
  GET_TRAVELER_INFORMATION_INCIDENT_LAYER: "GET_TRAVELER_INFORMATION_INCIDENT_LAYER",
  GET_TRAVELER_INFORMATION_INCIDENT_LAYER_SUCCESS: "GET_TRAVELER_INFORMATION_INCIDENT_LAYER_SUCCESS",
  GET_TRAVELER_INFORMATION_INCIDENT_LAYER_FAILURE: "GET_TRAVELER_INFORMATION_INCIDENT_LAYER_FAILURE",
  SET_MAP_EDIT_INC_LOC: "SET_MAP_EDIT_INC_LOC",
  SET_INC_LOC_LAT_LONG_FROM_MAP: "SET_INC_LOC_LAT_LONG_FROM_MAP",
  SET_INC_LOC_LAT_LONG_FROM_MAP_SUCCESS: "SET_INC_LOC_LAT_LONG_FROM_MAP_SUCCESS",
  SET_INC_LOC_LAT_LONG_FROM_MAP_FAILURE: "SET_INC_LOC_LAT_LONG_FROM_MAP_FAILURE",
  SET_INC_ADDRESS_FROM_MAP: "SET_INC_ADDRESS_FROM_MAP",

  // Map Annotations
  CREATE_MAP_ANNOTATION: "CREATE_MAP_ANNOTATION",
  CREATE_MAP_ANNOTATION_SUCCESS: "CREATE_MAP_ANNOTATION_SUCCESS",
  CREATE_MAP_ANNOTATION_FAILURE: "CREATE_MAP_ANNOTATION_FAILURE",
  UPDATE_MAP_ANNOTATION: "UPDATE_MAP_ANNOTATION",
  UPDATE_MAP_ANNOTATION_SUCCESS: "UPDATE_MAP_ANNOTATION_SUCCESS",
  UPDATE_MAP_ANNOTATION_FAILURE: "UPDATE_MAP_ANNOTATION_FAILURE",
  DELETE_MAP_ANNOTATION: "DELETE_MAP_ANNOTATION", 
  DELETE_MAP_ANNOTATION_SUCCESS: "DELETE_MAP_ANNOTATION_SUCCESS",
  DELETE_MAP_ANNOTATION_FAILURE: "DELETE_MAP_ANNOTATION_FAILURE",
  GET_MAP_ANNOTATIONS: "GET_MAP_ANNOTATIONS",
  GET_MAP_ANNOTATIONS_SUCCESS: "GET_MAP_ANNOTATIONS_SUCCESS",
  GET_MAP_ANNOTATIONS_FAILURE: "GET_MAP_ANNOTATIONS_FAILURE",

  // User Auto Suggest
  GET_ALL_USER_INFO: "GET_ALL_USER_INFO",
  GET_ALL_USER_INFO_SUCCESS: "GET_ALL_USER_INFO_SUCCESS",
  GET_ALL_USER_INFO_FAILURE: "GET_ALL_USER_INFO_FAILURE",
};
