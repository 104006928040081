import axios from "axios";

export const createMapAnnotation = (incId: string, geoId: string, geojsonString: string, note: string, primaryLocation: string, perimeterRadius: string ): any => {
    let postBody = {
      id: geoId,
      geojson: geojsonString,
      note: note,
      primaryLocation: primaryLocation,
      perimeterRadius: perimeterRadius
      
    }
    return axios.post("/Incidents/v1/" + incId + "/map", postBody);
  };

export const updateMapAnnotation = (incId: string, geoId: string, geojsonString: string, primaryLocation: string, perimeterRadius: string ): any => {
  let putBody = {
    id: geoId,
    geojson: geojsonString,
    primaryLocation: primaryLocation,
    perimeterRadius: perimeterRadius
  }
    return axios.put("/Incidents/v1/" + incId + "/map/" + geoId, putBody);
  };

export const deleteMapAnnotation = (incId: string, geoId: string ): any => {
    return axios.delete("/Incidents/v1/" + incId + "/map/" + geoId);
  };

export const getMapAnnotations = (incId: string): any => {
    return axios.get("/Incidents/v1/" + incId + "/map");
  };



  export const getHighwayAlerts = ():any => {
    return axios.get("/partners/TravelerInfo/HighwayAlerts");
  }